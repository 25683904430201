.title-card {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;

  &__element {
    margin: .25rem 0;
  }

  &__place-n-date {
    color: #000;
    @include fontStyling($nunitoFont, 400);
    font-size: 1.25rem;
    text-transform: none;
    margin: 1rem 0 1.5rem 0;
  }

  &__congress-number {
    color: map_get($themeColors, themeColorLight);
    @include fontStyling($nunitoFont, 700);
    font-size: 1.25rem;
    display: inline-block;
    padding: .25rem;
  }

  &__specialization {
    color: #000;
    @include fontStyling($bebasFont, 400);
    font-size: 5rem;
    line-height: 1;
  }

  &__claim {
    color: #000;
    @include fontStyling($bebasFont, 400);
    font-size: 2rem;
    margin-top: 0;
  }
}

@include media-breakpoint-down(sm) {
  .title-card {
    max-width: unset;
    padding: 1rem 0;

    &__specialization {
      font-size: 70px;
      padding-top: 0;
      line-height: 1;
    }

    &__place-n-date {
      margin: .5rem 0;
    }

    &__claim {
      font-size: 1.5rem;
    }
  }
}