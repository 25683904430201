.congress-sponsors {
  &__row {
    padding-bottom: 1rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: rgba(200, 200, 200, 0.2);
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__text {
    text-transform: uppercase;
    text-align: center;
    @include fontStyling($nunitoFont, 300);
    margin-top: 2rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  &__logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    flex-wrap: wrap;
  }

  &__logo {
    max-width: 24%;
    max-height: 60px;
    margin: .75rem;
  }
}