.marketing-alerts-popup {
  @include fontStyling($nunitoFont, 400);
  max-width: 30%;

  & h3, h4, h5, h6 {
    font-weight: 700;
    @include setThemeColor(color, themeColorDark);
    font-size: 1.25rem;
  }

  & p {
    font-size: 1rem;
  }

  & ul {
    list-style: none;
  }

  & li {
    margin: .5rem 0 .5rem 1.5rem;
    position: relative;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      left: -1.5rem;
      @include setThemeColor(background, themeColorDark);
      clip-path: polygon(30% 80%, 91% 40%, 98% 50%, 30% 95%, 4% 71%, 12% 62%);
    }
  }

  & a {
    font-weight: 700;
    @include setThemeColor(color, themeColorDark);
    font-size: 1rem;
    text-transform: uppercase;
  }
}

@include media-breakpoint-down(md) {
  .marketing-alerts-popup {
    max-width: unset;
  }
}