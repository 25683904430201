.slick{
  &-prev {
    display: none !important;
  }

  &-next {
    right: $insetSpacer;
    width:42px;
    height:42px;
    &:before {
      @include setThemeColor(color, themeColorDark);
      font-size: 42px;
    }
  }
}

@include media-breakpoint-down(sm){
  .slick{
    &-prev {
      display: block;
      left: 10px
    }
    &-next {
      right: 10px;
    }
  }
}
