.card-container {
  width: 100%;
  @include setBackgroundColor(white);
  box-shadow: 0 0 10px rgba(49, 49, 49, .14);
  display: flex;

  &_no-frame {
    box-shadow: none;
  }
}

.cards {
  padding-top: $insetSpacer;

  &__title {
    @include fontStyling($nunitoFont, 300);
    font-size: 14px;
    text-transform: uppercase;
  }

  &__item {
    margin-top: $spacer;
  }
}

.card {
  width: 100%;
  @include fontStyling($nunitoFont, 300);
  font-size: 16px;
  padding: $insetSpacer;

  &__image-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__image {
    height: 100%;
    max-height: 160px;
  }

  &__promo-info {
    position: absolute;
    top: 1rem;
    right: 2rem;
    background-color: #FE1400;
    @include setFontColor(whiteFont);
    @include fontStyling($openFont, 700);
    text-transform: uppercase;
    padding: .25rem;
  }
}

.card-stream {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__title {
    font-size: 1.125rem;
    font-weight: 600;
  }

  &__item {
    margin: $spacer/2 0;
  }

  &__price {
    @include fontStyling($nunitoFont, 600);
    font-size: 3rem;
    @include setFontColor(greyFont);
  }

  &__button {
    font-size: 1rem;
    border-radius: unset;
  }
}

.card-text {
  @include setFontColor(lightGreyFont);
  @include fontStyling($nunitoFont, 300);

  &__title,
  &__paragraph {
    margin-bottom: $spacer;
  }

  &__title {
    font-weight: 600;
    font-size: 1.125rem;
  }

  &__paragraph {
    line-height: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.card-list {
  @include setFontColor(lightGreyFont);
  @include fontStyling($nunitoFont, 300);
  font-size: 1rem;

  &__title {
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: $spacer;
  }

  &__item {
    line-height: 1.75rem;
    position: relative;
  }

  &__tick {
    width: .75rem;
    height: .75rem;
    margin-right: $insetSpacer/3;
    display: inline-block;
    @include setBackgroundColor(success);
    clip-path: polygon(44% 76%, 86% 11%, 100% 21%, 48% 100%, 0 64%, 11% 51%);
  }
}

@include media-breakpoint-down(sm) {
  .card {
    padding: $spacer 0;

    &__image {
      height: unset;
      max-width: 100%;
      max-height: unset;
      margin-bottom: 1rem
    }
  }
}

@include media-breakpoint-down(xs) {
  .card {
    &__image {
      max-width: 60%;
    }
  }
}