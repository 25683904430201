.section-title {
  @include setFontColor(darkGreyFont);
  @include fontStyling($nunitoFont, 300);
  font-size: 1.625rem;
  text-transform: uppercase;
  margin-bottom: 1rem;

  &_small {
    font-size: 0.875rem;
  }

  &_bold {
    @include fontStyling($nunitoFont, 600)
  }

  &_white {
    @include setFontColor(whiteFont);
    margin: 0;
  }
}