.invitation {
  &__row {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    &_space {
      justify-content: space-evenly;
    }
  }

  &__lecturers {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__element {
    margin: 0 .5rem;
  }

  &__content {
    padding: 0.5rem;
  }

  &__text {
    @include setFontColor(lightGreyFont);
    @include fontStyling($nunitoFont, 300);
    font-size: 18px;
    line-height: 23px;
    padding: .25rem 0;

    &_margined {
      margin: 0 .5rem;
      max-width: 100%;
      text-align: center;
    }
  }

  &__lecturer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 12rem;
    align-items: center;
  }

  &__photo-container {
    width: 9rem;
    height: 9rem;
    box-shadow: 0 0 7px rgba(200, 200, 200, .6);
    display: flex;
    justify-content: center;
    overflow: hidden;
    border: 5px solid white;

    & img {
      object-fit: contain;
      object-position: center;
    }
  }
}

@include media-breakpoint-down(sm) {
  .invitation {
    &__row {
      flex-wrap: wrap;
    }

    &__lecturer {
      flex-direction: row;
      width: 100%;
      text-align: left;
      margin-top: 1rem;
      justify-content: flex-start;
    }

    &__text {
      &_margined {
        margin: 0 1rem;
        text-align: left;
        font-size: 20px;
      }
    }
  }
}

