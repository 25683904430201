.header-podyplomie {
  background-color: map_get($podyplomieColors, main);

  & * {
    @include fontStyling($nunitoFont, 400);
  }


  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    margin: 0 .375rem;
    &_relative {
      position: relative;
    }
  }

  &__logo {
    width: 100%;
    min-width: 130px;
  }

  &__form {
    display: flex;
    flex-grow: 1;
    padding: 0 1.5rem;
  }

  &__search-input {
    flex-grow: 1;
    background: white;
    height: 2rem;
    line-height: 2em;
    padding-left: .5rem;
    font-size: 1rem;
  }

  &__separator {
    width: 100%;
    margin-top: .5rem;

    &_vertical {
      flex: 1;
    }
  }
}

.menu-link {
  display: flex;
  align-items: center;
  padding: .375rem .25rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  font-size: 1rem;
  @include fontStyling($nunitoFont, 400);
  border: none;

  &__login {
    line-height: 20px;
    text-transform: uppercase;

    &_green {
      background-color: map_get($podyplomieColors, success);
    }
  }

  &_special {
    color: map_get($podyplomieColors, enhance);

    &:hover, &:active, &:focus {
      color: map_get($podyplomieColors, enhance);
    }
  }

  &_promo {
    font-weight: 900;
    font-style: italic;
    position: relative;
  }

  &__promo-badge {
    position: absolute;
    top: -60%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__badge {
    position: absolute;
    text-align: center;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: map_get($podyplomieColors, success);
    width: 1rem;
    height: 1rem;
    font-size: .75rem;
    line-height:1rem;
  }

  &_dropdown {
    &:after {
      content: '';
      position: absolute;
      right: -5px;
      top: 50%;
      width: .375rem;
      height: .375rem;
      transform: translateY(-50%);
      background-color: white;
      clip-path: polygon(50% 70%, 0 0, 100% 0);
    }
  }

  &_logo {
    max-width: 180px;
  }

  &_subscribe {
    background-color: map_get($podyplomieColors, subscribe);
    display: inline-flex;
    text-align: left;
    text-transform: none;
  }

  &_highlighted {
    color: map_get($podyplomieColors, enhance)
  }
}

.mobile-menu {
  display: flex;
  align-items: center;
}

@include media-breakpoint-up(lg) {
  .header-podyplomie {
    &__item {
      &_grow {
        flex-grow: 1;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .header-podyplomie {
    &__item {
      margin: 0 0.25rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .header-podyplomie {
    &__container {
      max-height: 49px;
    }

    &_fixed {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
    }

    &__item {
      margin: 0;
    }

    &__form {
      transition: .3s all;
      position: absolute;
      padding: .5rem;
      width: 100%;
      top: 0;
      transform: translateY(-100%);
      left: 0;
      z-index: 10;
      background-color: map_get($podyplomieColors, main);

      &_show {
        transform: translateY(80%);
      }
    }
  }

  .menu-link {
    padding: .5rem .25rem;
    font-size: .8125rem;

    &_special {
      color: black;

      &:hover, &:active, &:focus {
        color: black;
      }
    }

    &_logo {
      max-width: 120px;
    }

    &_mobile {
      width: 100%;
      margin-right: 0;
      justify-content: center;
    }

    &_subscribe {
      width: 80%;
    }

    &_dropdown {
      width: 100%;

      &:after {
        clip-path: none;
        border: 1px solid transparent;
        border-bottom-color: black;
        border-right-color: black;
        width: .625rem;
        height: .625rem;
        transform: rotate(45deg) translate(-50%, -50%);
      }
    }

    &__promo-badge {
      top: 50%;
      left: unset;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }

  .mobile-menu {
    background-color: white;
    position: fixed;
    flex-direction: column;
    z-index: 20;
    left: 0;
    top: 44px;
    width: 100vw;
    height: calc(100vh - 44px);
    transition: .3s transform;
    transform: translateX(-100%);

    &_show {
      transform: translateX(0%);
    }

    &__list {
      flex-direction: column;
      width: 80%;
      max-height: 70%;
      overflow: auto;
    }

    &__item {
      padding: 0 1rem;
      border-bottom: 1px solid #E1E1E1;
      width: 100%;
      flex-direction: column;
      order: 2;

      &_special {
        order: 1;
      }

      &_mt {
        align-items: center;
        border-bottom: none;
        margin-bottom: .5rem;
      }
    }

    &__link {
      color: black;
      padding: 1rem 0 .75rem 0;
      line-height: 1.5;
    }
  }
}
