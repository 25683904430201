@import 'reset';
@import 'imports';
@import 'variables';

*,
*:after,
*:before {
  box-sizing: border-box;
}

* {
  &:focus {
    outline: #283583 auto 1px;
  }
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}