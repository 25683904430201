.page {
  &__element {
    margin-top: 1rem
  }

  &__section {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@include media-breakpoint-down(sm) {
  .page {
    &__section {
      width: auto;
    }
  }
}