.congress-list {
  margin-top: 1rem;

  &__title {
    @include fontStyling($robotoFont, 700);
    @include setFontColor(listDarkFont);
    font-size: 30px;
    line-height: 52px;
    text-align: center;
  }

  &__rules {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }

  &__rules-link {
    @include fontStyling($robotoFont, 400);
    font-size: 1.25rem;
    @include setFontColor(listDarkFont);
    text-decoration: none;
    display: flex;
    align-items: center;

    & svg {
      margin-right: 1rem;
    }
  }
}

.congress-list-archive {
  border-top: 1px solid map_get($pageColors, listDarkGrey);
  margin: 1rem 0;

  &__button {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    @include setFontColor(listDarkFont);
    margin: 1rem 0;
    position: relative;

    & .arrow {
      position: absolute;
      right: 0;
    }
  }

  &__row {
    transition: .3s all;
    max-height: 0;
    visibility: hidden;
    opacity: 0;

    & .congress-list-archive-card {
      transition: .3s all;
      max-height: 0;
      min-height: 0;
    }

    &_show {
      max-height: 400rem;
      visibility: visible;
      opacity: 1;

      & .congress-list-archive-card {
        max-height: 27rem;
        min-height: 27rem;
      }
    }
  }
}

.congress-list-archive-card {
  background-color: #F6F6F6;
  margin: 1rem 0;
  padding: 1rem;

  &__title {
    @include setFontColor(listDarkFont);
    font-size: 1.25rem;
    margin-bottom: 1rem;
    @include fontStyling($robotoFont, 400);
  }

  &__link {
    text-decoration: none;
    @include setFontColor(navyFont);
    display: block;
    padding: .375rem 0;
    font-size: .875rem;
    line-height: 1.1;
  }
}

@include media-breakpoint-down(sm) {
  .congress-list {
    &__title {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  .congress-list-archive {
    &__row {
      & .congress-list-archive-card {
        min-height: unset;
      }

      &_show {
        & .congress-list-archive-card {
          min-height: unset;
          max-height: 40rem;
        }
      }
    }
  }

}