.patronage {
  display: flex;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid map_get($pageColors, borderGrey);

  &__texts {
    @include fontStyling($nunitoFont, 400);
    font-size: 1.125rem;
    line-height: 1.5;
    color: map_get($fontColors, lightGreyFont)
  }

  &__title {
    @include fontStyling($nunitoFont, 400);
    padding-bottom: .75rem;
  }

  &__image {
    max-width: 5rem;
    margin-right: 1rem;
  }
}