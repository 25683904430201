.promoter-section {

  &__img {
    max-width: 100%;
  }

  &__description {
    @include fontStyling($nunitoFont, 300);
    font-size: 1rem;
    line-height: 1.375rem;
  }

  &__paragraph {
    margin-bottom: .5rem;
    & p {
      margin-bottom: .5rem;
    }
  }
}