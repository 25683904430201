.purchase-summary {
  padding: 0 1rem;
  @include fontStyling($nunitoFont, 400);

  &__checkbox,
  &__radio {
    display: block;
    position: absolute;
    opacity: 0;

    & + .purchase-summary__label {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      z-index: 5;

      &:before {
        content: '';
        margin-right: 1rem;
        vertical-align: text-top;
        display: inline-block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-color: #DFDFDF;
      }
    }

    &:checked + .purchase-summary__label:before{
      background-color: map_get($pageColors, success);
    }
  }

  &__radio {
    & + .purchase-summary__label {
      &:before {
        border-radius: 50%;
      }
    }
  }

  &__input {
    display: flex;
    justify-content: space-between;
    margin: .5rem 0;

    &_active {
      background-color: #3D4EB8;
    }

    &_disabled {
      position: relative;
      pointer-events: none;
      @include setFontColor(lightGreyFont);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        background-color: rgba(171, 171, 171, 0.2);
      }
    }
  }

  &__label {
    @include fontStyling($nunitoFont, 300);
    padding-left: 1rem;
  }
}


.purchase-summary__elem {
  padding: 1rem 0;
  &:not(:first-child) {
    border-top: 1px solid #D5D5D5;
  }
}

@include media-breakpoint-down(sm) {
  .purchase-summary {
    padding: 0;

    &__label {
      font-size: .875rem;
    }
  }
}