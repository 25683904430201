.popup-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(130, 130, 130, .5);
  z-index: 30;
}

.popup {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: $spacer*2;
  padding: $insetSpacer*1.5;
  @include setBackgroundColor(white);
  z-index: 35;
  max-width: 60%;
  max-height: 90%;
  overflow-y: scroll;

  &_video {
    max-width: 80%;
  }

  &__image {
    max-width: 100%;
  }

  &__text {
    margin-bottom: $spacer/2;
    line-height: 120%;

    &_grey {
      @include setFontColor(greyFont);
    }
  }

  &__center-row {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  &__close {
    position: absolute;
    right: $insetSpacer/2;
    top: $insetSpacer*.75;
    padding: 0;
    z-index: 30;
  }
}

@include media-breakpoint-down(lg) {
  .popup {
    max-width: 80%;
    width: 80vw;
  }
}

@include media-breakpoint-down (md) {
  .popup {
    max-width: inherit;
    width: 90%;
  }
}