.grid {
  &__container {
    width: 100%;
    padding-right: $grid-gutter/2;
    padding-left: $grid-gutter/2;
    margin: 0 auto;
    max-width: 1140px !important;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -($grid-gutter/2);
    margin-left: -($grid-gutter/2);
  }

  &__col {
    @for $i from 12 through 1 {
      &_#{$i} {
        @media(min-width: 1px) {
          min-height: 1px;
          width: (100%/12)*$i;
          float: left;
          padding-left: $grid-gutter/2;
          padding-right: $grid-gutter/2;
        }
      }
    }

    @for $i from 12 through 1 {
      &_sm_#{$i} {
        @include media-breakpoint-up(sm) {
          width: (100%/12)*$i;
          min-height: 1px;
          padding-left: $grid-gutter/2;
          padding-right: $grid-gutter/2;
          float: left;
        }
      }
    }

    @for $i from 12 through 1 {
      &_md_#{$i} {
        @include media-breakpoint-up(md) {
          width: (100%/12)*$i;
          min-height: 1px;
          padding-left: $grid-gutter/2;
          padding-right: $grid-gutter/2;
          float: left;
        }
      }
    }

    @for $i from 12 through 1 {
      &_lg_#{$i} {
        @include media-breakpoint-up(lg) {
          width: (100%/12)*$i;
          min-height: 1px;
          padding-left: $grid-gutter/2;
          padding-right: $grid-gutter/2;
          float: left;
        }
      }
    }

    @for $i from 12 through 1 {
      &_xl_#{$i} {
        @include media-breakpoint-up(xl) {
          width: (100%/12)*$i;
          min-height: 1px;
          padding-left: $grid-gutter/2;
          padding-right: $grid-gutter/2;
          float: left;
        }
      }
    }
  }

  &__negative-margin {
    &-left {
      margin-left: -($grid-gutter/2);
    }
    &-right {
      margin-right: -($grid-gutter/2);
    }
  }

  &__padding {
    &-vertical {
      padding: 1rem 0;

      &_top {
        padding-top: 1rem;
      }

      &_bottom {
        padding-bottom: 1rem;
      }
    }

    &-horizontal {
      padding: 0 $grid-gutter/2;

      &_left {
        padding-left: $grid-gutter/2;
      }

      &_right {
        padding-right: $grid-gutter/2;
      }
    }
  }
}
