.main-summary {
  &__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &__product-info {
    display: flex;
    margin: 2rem 0;
  }

  &__price {
    font-size: 2.5rem;
    font-weight: bolder;
  }

  &__name {
    font-size: 1.25rem;
  }

  &__date {
    font-size: 0.875rem;
  }

  &__price, &__name, &__date {
    padding-bottom: 1rem;
    line-height: 1;
  }

  &__image {
    margin-right: 1rem;
    flex-shrink: 0;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: rgba(197, 197, 197, .4) no-repeat center;
    background-size: cover;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@include media-breakpoint-down(sm) {
  .main-summary {
    &__title {
      font-size: 1.25rem;
    }

    &__price {
      font-size: 1.5rem;
    }

    &__name {
      font-size: 0.875rem;
    }

    &__date {
      font-size: 0.625rem;
    }

    &__image {
      margin-right: .5rem;
      width: 5rem;
      height: 5rem;
    }

    &__product-info {
      margin: 1rem 0;
    }
  }
}