.arrow {
  margin: .5rem;
  display: inline-block;

  &-sm {
    width: .375rem;
    height: .375rem;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
  }

  &-md {
    width: .75rem;
    height: .75rem;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
  }

  &-lg {
    width: 1.5rem;
    height: 1.5rem;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
  }

  &-sm,
  &-md,
  &-lg {
    &_right {
      transform: rotate(-45deg);
    }

    &_left {
      transform: rotate(135deg);
    }

    &_green {
      border-bottom-color: map_get($pageColors, success);
      border-right-color: map_get($pageColors, success);
    }

    &_black {
      border-bottom-color: #2A2A2A;
      border-right-color: #2A2A2A;
    }
  }

  &-sm,
  &-lg {
    &_down {
      transform: translateY(-25%) rotate(45deg);
    }

    &_up {
      transform: translateY(25%) rotate(-135deg) ;
    }
  }

  &-md {
    &_down {
      transform: translateY(10%) rotate(45deg);
    }

    &_up {
      transform: translateY(50%) rotate(-135deg) ;
    }
  }
}