.tooltip {
  position: absolute;
  bottom: 150%;
  left: 0;
  background-color: map_get($pageColors, white);
  @include setFontColor(greyFont);
  @include fontStyling($nunitoFont, 300);
  padding: .25rem;
  width: 10rem;
  font-size: .75rem;
  opacity: 0;
  visibility: hidden;
  transition: .25s all;
  text-transform: none;

  &_show {
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }
}

@include media-breakpoint-down(xs) {
  .tooltip {
    top: 150%;
    bottom: unset;
    width: auto;
  }
}