.tickets-row {
  position: relative;
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(to right, rgba(249,249,249,1) 0%, rgba(249,249,249,1) 70%, rgba(249,249,249,.3) 100%);
  }
}

.tickets {
  &__row  + &__row {
    margin-top: $spacer;
  }
}

@include media-breakpoint-down(sm) {
  .tickets-row {
    background-image: none;
  }
}