.accordion {
  &__item {
    border-bottom: 1px solid black;
    padding: 1rem 0;
    overflow: hidden;
  }

  &__flex-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  &__logo {
    max-height: 4rem;
    margin-left: 1rem;
  }

  &__map {
    margin-top: 1rem;
    width: 100%;
  }

  &__text {
    line-height: 1.375rem;
  }

  &__text-col {
    max-width: 70%;
    margin-left: 1rem;
  }

  &_align-center {
    display: flex;
    align-items: center;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include fontStyling($nunitoFont, 600);
    cursor: pointer;
    @include setFontColor(greyFont);
  }

  &__collapsible {
    transition: .15s;
    max-height: 50rem;
    padding-top: 1rem;
    visibility: visible;
    opacity: 1;

    &_hide {
      visibility: hidden;
      max-height: 0;
      padding-top: 0;
      opacity: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .accordion {
    &__flex-row {
      display: block
    }
    &__text-col {
      max-width: unset;
      padding-bottom: 1rem;
    }
    &__text {
      & img {
        max-width: 100%;
      }
    }
  }
}