.congress-exhibitors {
  margin-bottom: 3rem;

  &__row {
    margin: 2rem 0;

    & .slick-track {
      display: flex;
      align-items: center;
    }

    & .slick-slide {
      margin: 0 1rem
    }
  }
}