.ticket-purchase-amount {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1.5rem 0;
  padding: .125rem;
  border-radius: 1rem;
  background-color: white;
  flex-wrap: wrap;
  align-self: center;

  &__number {
    font-size: .75rem;
    background-color: white;
    @include fontStyling($nunitoFont, 300);
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    color: black;
    margin: 0 .125rem;
    border: none;
  }

  &__button {
    background-color: map_get($podyplomieColors, subscribe);
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    font-size: .875rem;
    padding: 0;

    &_disabled {
      background-color: map_get($podyplomieColors, disabled);
      color: black;
      pointer-events: none;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 1px 2px rgba(40, 53, 131, .5);
    }
  }
}

@include media-breakpoint-down(md) {
  .ticket-purchase-amount {
    &__button {
      margin: 0
    }
  }
}

@include media-breakpoint-down(xs) {
  .ticket-purchase-amount {
    margin-top: .5rem;
    margin-left: 0;
  }
}