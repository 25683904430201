.workshops-and-entertainment {
  display: flex;
}

.workshops {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &__summary {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__sum {
    display: flex;
    align-items: flex-end;
    padding-right: 1rem;
    font-weight: bolder;
    font-size: 0.875rem;
  }

  &__amount {
    margin-left: 1rem;
    font-size: 1.5rem;
    white-space: nowrap;
  }
}

.workshops-list {
  list-style: none;
  margin: 1rem 0;
  max-height: 15rem;
  overflow: hidden;
  overflow-y: scroll;
}

.workshop-item {
  padding: .5rem 0;
  transition: .25s all;

  &_checked {
    background-color: #F6F6F6;
  }

  &__price {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding-right: 1rem;
    align-items: center;
    font-weight: bolder;
  }

  &_sent {
    background-color: #F2F2F2;
    color: #AAA;
    opacity: .75;

    & label, & input {
      pointer-events: none;
    }

    & label:before {
      opacity: .5;
    }
  }
}

.entertainment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #F6F6F6;
  padding: .5rem;
  height: 100%;

  &__title,
  &__name,
  &__price {
    text-align: center;
    padding-bottom: 1rem;
    font-size: .875rem;
  }

  &__form {
    text-align: center;
  }

  &__name {
    font-weight: bolder;
  }

  &__price {
    font-weight: bolder;
    font-size: 1.5rem;
  }

  &__image {
    height: 7rem;
    width: auto;
    background: center no-repeat;
    background-size: contain;
    margin-bottom: .5rem;
  }
}

@include media-breakpoint-down(sm){
  .workshops-list {
    max-height: unset;
  }

  .workshop-item {
    &__price {
      font-size: .875rem;
    }
  }

  .workshops {
    &__sum {
      flex-direction: column;
    }
  }

  .entertainment {
    margin: 1rem 0;
    height: unset;
  }
}