.menu {
  position: fixed;
  text-align: right;
  z-index: 10;
  transition: .5s;
  margin-right: 0.5625rem;
  max-width: calc(1140px/4 - 32px);

  &_top-value {
    top: 0;
  }

  &__item {
    margin-bottom: .75rem ;
  }

  &__header,
  &__footer {
    transition: .5s max-height, .5s transform;
    transform: translateX(-400%);
    max-height: 0;

    &_show {
      transition: .5s max-height, .5s transform;
      transform: translateX(0);
      max-height: 500px;
    }
  }

  &__place-n-date {
    @include fontStyling($nunitoFont, 400);
  }

  &__founder,
  &__social {
    @include fontStyling($nunitoFont, 300);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__social {
    &_icon {
      margin-left: .75rem;
    }

    &_link {
      display: inline-block;
    }
  }

  &__founder-img {
    margin-left: 1rem;
    max-height: 30px;
  }

  &__title {
    color: #000;
    @include fontStyling($bebasFont, 400);
    margin: 0;
    line-height: 1.1;
  }
}

@include media-breakpoint-down(lg) {
  .menu {
    left: 0.5rem;

  }
}

@include media-breakpoint-down(md) {
  .menu {
    bottom: 0;
    max-width: 100%;
    margin-left: -($grid-gutter/2);
    margin-right: -($grid-gutter/2);
    left: unset;

    &_top-value {
      top: unset;
    }
  }
}

@include media-breakpoint-down(md) {
  .menu {
    bottom: 0;
    max-width: 100%;
    margin-left: -($grid-gutter/2);
    margin-right: -($grid-gutter/2);
    left: unset;

    &__list {
      display: flex;
      flex-wrap: nowrap;
      @include setBackgroundColor(white);
      box-shadow: 0 0 10px 0 rgba(181, 181, 181, 0.7);
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    &__item {
      flex: 0 0 auto;
      margin: $spacer/4 0;
      &:first-child {
        margin-left: $spacer/2
      }

      &:last-child {
        margin-right: $spacer/2;
      }
    }

    &__header,
    &__footer,
    &__social {
      display: none;
    }
  }
}