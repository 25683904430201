@mixin setBackgroundColor ($value) {
  background-color: map_get($pageColors, $value);
}

@mixin setFontColor ($value) {
  color: map_get($fontColors, $value);
}

@mixin setThemeColor($property, $value) {
  @if ($property == color) {
    color: map_get($themeColors, $value);
  } @else if($property == background) {
    background-color: map_get($themeColors, $value);
  }
}