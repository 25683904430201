.input-wrapper {
  display: flex;
  position: relative;
  flex-grow: 2;
}

.input {
  &_red {
    border: 1px solid red !important;
  }

  &[type='checkbox'] {
    margin-right: .5rem;
  }
}

.textarea, .input[type='text'], .input[type='email'], .input[type='tel'] {
  border: 1px solid #e3e3e3;
  line-height: 22px;
  border-radius: 30px;
  @include setBackgroundColor(simpleWhite);
  padding: .5rem 1rem;
}

input[type='number'] {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}