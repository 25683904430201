$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

$grid-gutter: 32px;

@font-face {
  font-family: 'Bebas Neue';
  src: url("../../fonts/bebas_neue.TTF");
  font-weight: 400;
}

$nunitoFont: 'Nunito Sans', sans-serif;
$openFont: 'Open Sans', sans-serif;
$bebasFont: 'Bebas Neue', sans-serif;
$robotoFont: 'Roboto', sans-serif;

$fontColors: (
    blackFont: #000000,
    darkGreyFont: #272727,
    listDarkFont: #2A2A2A,
    greyFont: #3A3A3A,
    listFont: #6b6b6b,
    lightGreyFont: #707070,
    greenFont: #52B54F,
    whiteFont: #FFFFFF,
    navyFont: #283583,
    lightBlueFont: #009FE3,
    pinkFont: #e5608e,
    warningFont: #ffc107,
);

$themeColors: (
    themeColorDark: #283583,
    themeColorLight: #009FE3,
    themeColorHighlight: #4054CF,
    themeColorLightBackground: #C9CCE0,
    themeColorBasic: #3A3A3A,
    themeColorLightGrey: #B7B7B7,
);

$pageColors: (
    contactIcon: #60C0DF,
    social: #009FE3,
    socialGrey: #5E5E5E,
    success: #21B01C,
    navyColor: #283583,
    background: #C9CCE0,
    backgroundHeader: #EFF0F8,
    backgroundLight: rgba(201, 204, 224, .5),
    textColBackground: #F4F5FF,
    ticketDisabled: #DAE5E9,
    sticker: #810E9A,
    pink: #e5608e,
    white: #FFFFFF,
    switcherGrey: #E5E5E5,
    linearBackground: #F9F9F9,
    whiteOnOpacity: rgba(255, 255, 255, .73),
    opacityOnBackground: rgba(255,255,255,0),
    transparent: transparent,
    neuro: map_get($themeColors, neuro),
    borderGrey: #C2C2C2,
    buttonDisabled: #9BB1CC,
    freeWorkshop: #4FB1B5,
    ticketOption: #2D3B92,
    listGrey: #6b6b6b,
    listDarkGrey: #2A2A2A
);

$podyplomieColors: (
    main: #0F4364,
    subscribe: #0069C5,
    disabled: #CCE1F3,
    mask: rgba(31, 31, 31, 0.3),
    success: #449D44,
    enhance: #ffcf00,
    footerBackground: #343a40,
    footerWhite: #FFFFFF,
    footerGrey: #7b7b7b
);

$insetSpacer: $grid-gutter/2;
$spacer: $grid-gutter/2;