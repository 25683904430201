.basic-info {
  position: relative;
  min-height: 12.5rem;
  background-image: url("../../../images/zdj-w-tle.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(201, 204, 224, 0.85);
    z-index: -1;
  }

  &__item {
    z-index: 10;
  }

  &__icon {
    fill: map_get($themeColors, themeColorDark)
  }

  &__row {
    justify-content: space-evenly;
  }
}

.basic-info-column {
  padding: $insetSpacer 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  &__item {
    margin: $spacer/2 0;
  }

  &__text {
    @include fontStyling($nunitoFont, 600);
    @include setThemeColor(color, themeColorDark);
    font-size: 2.5rem;
    text-align: center;

    &_small {
      display: flex;
      align-items: center;
      font-size: 1.125rem;
      height: 2.1rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .basic-info-column {
    &__item {
      margin: $spacer/4 0;
    }

    &__text {
      font-size: 2.125rem;

      &_small {
        font-size: 1rem;
      }
    }
  }
}