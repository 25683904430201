.error-handler {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;

  &__title, &__text {
    text-align: center;
    max-width: 70%;
  }

  &__title {
    font-size: 24px;
    @include fontStyling($bebasFont, 400);
    margin: 1rem 0;
  }

  &__text {
    @include fontStyling($nunitoFont, 400);
    font-size: 18px;
    margin-bottom: 1rem;
  }
}