$badgeColors: (
        newEdition: #ffcf00,
        promotion: #ed1c24,
        recommend: #04B1BE
);

.menu-badge {
  color: white;
  @include fontStyling($openFont, 700);
  padding: .25rem .5rem;
  border-radius: 3px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  margin-left: .25rem;

  @each $badgeType, $bgColor in $badgeColors {
    &_#{$badgeType} {
      background-color: $bgColor;
    }
  }
}