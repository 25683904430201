.ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  padding: $insetSpacer;
  box-shadow: 0 0 10px rgba(49,49,49,0.14);
  position: relative;
  height: 100%;
  @include setThemeColor(background, themeColorDark);
  @include setFontColor(whiteFont);
  @include fontStyling($nunitoFont, 300);

  &_inactive {
    @include setBackgroundColor(ticketDisabled);
    @include setFontColor(greyFont);
  }

  &__main-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__text {
    font-size: 1.125rem;
    text-align: center;
    margin-bottom: $spacer/2;

    &_sm {
      font-size: .875rem;
    }

    &_inactive {
      @include setFontColor(greyFont)
    }

    &_space-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &_bold {
      font-weight: bolder;
    }
  }

  &__sticker {
    @include fontStyling($nunitoFont, 600);
    padding: $insetSpacer/2 $insetSpacer/2;
    font-size: .75rem;
    top: 3rem;
  }

  &__call-to-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 3rem;
  }

  &__prices {
    padding-top: 2rem;

    &_inactive {
      color: #9BB1CC;
    }
  }

  &__base-price {
    font-size: 3rem;
    @include fontStyling($nunitoFont, 600);
  }

  &_open {
    &:before {
      content: '';
      width: 2rem;
      height: 2rem;
      @include setThemeColor(background, themeColorDark);
      position: absolute;
      bottom: -1rem;
      z-index: -1;
      transform: rotate(45deg);
    }
  }

  &__separator {
    width: 90%;
    height: 1px;
    background-color: rgba(255, 255, 255, .6);

    &_inactive {
      background-color: rgba(100, 100, 100, .3);
    }
  }
}

@include media-breakpoint-down(sm) {
  .ticket {
    margin-bottom: $spacer;
    justify-content: space-between;

    &__element + &__element {
      margin-top: 0;
    }

    &__sticker {
      font-size: .75rem;
    }

    &__prices {
      padding-top: 1rem;
      font-size: 2rem;
      padding-bottom: 0;

      &_inactive {
        padding-top: .5rem;
        padding-bottom: .5rem;
      }
    }

    &__call-to-action {
      height: unset;
    }

    &_active {
      background-position: left;
      background-size: cover;

      &:after {
        content: '';
        background-color: map_get($themeColors, themeColorDark);
        opacity: 0.8;
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
    }
  }
}