.dropdown {
  position: absolute;
  display: none;
  flex-direction: column;
  top: 150%;
  left: 0;
  width: 17rem;
  z-index: 11;
  background-color: white;
  box-shadow: 3px 3px 6px rgba(71, 71, 71, 0.3);

  &_show {
    display: flex;
  }

  &__item {
    padding: .5rem;
    font-size: .875rem;
    @include setFontColor(greyFont);
    text-decoration: none;
    line-height: 1.5rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-down(md) {
  .dropdown {
    position: static;
    box-shadow: none;
    margin-bottom: 1rem;
    max-width: 100%;
  }
}