@mixin linkReset {
  text-decoration: none;
  display: inline-block;
}

@mixin buttonReset {
  background-color: transparent;
  border: none;
}

@mixin basicButton {
  @include linkReset;
  @include buttonReset;
  font-size: 16px;
  border-radius: 100px;
  color: white;

  @include media-breakpoint-down(md){
    padding:0.625rem;
    margin: 0.25rem 0;
    font-weight: 600;
    font-size: 14px;
  }
}

@mixin buttonSuccess {
  @include basicButton;
  @include fontStyling($nunitoFont, 600);
  @include setBackgroundColor(success);
  @include setFontColor(whiteFont);
  border: 2px solid  map_get($pageColors, success);
  text-align: center;
}

@mixin buttonInactive {
  @include basicButton;
  @include fontStyling($nunitoFont, 600);
  @include setBackgroundColor(buttonDisabled);
  @include setFontColor(whiteFont);
}

@mixin buttonSuccessReverted {
  @include basicButton;
  @include fontStyling($nunitoFont, 600);
  @include setBackgroundColor(transparent);
  @include setFontColor(greenFont);
  box-shadow: inset 0 0 0 1px map_get($pageColors, success);
  padding: .625rem;

  @include media-breakpoint-down(md){
    padding: .75rem;
  }
}

@mixin buttonPink {
  @include basicButton;
  @include fontStyling($nunitoFont, 600);
  @include setThemeColor(background, themeColorDark);
  @include setFontColor(whiteFont);
}

@mixin buttonPinkReverted {
  @include basicButton;
  @include fontStyling($nunitoFont, 700);
  @include setBackgroundColor(white);
  @include setThemeColor(color, themeColorDark);
  font-size: .875rem;
}

@mixin menuLink {
  @include linkReset;
  @include fontStyling($nunitoFont, 600);
  text-transform: uppercase;
}