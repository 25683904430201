.details-for-city {
  display: flex;
  align-items: center;
  border-bottom: 1px solid map_get($pageColors, borderGrey);
  @include fontStyling($nunitoFont, 300);

  &__text {
    @include fontStyling($nunitoFont, 300);
    font-size: 1.25rem;
  }

  &__item {
    margin-left: 1rem;
  }
}