.congress-card {
  width: 100%;
  box-shadow: 0 0 10px rgba(150, 150, 150, .3);
  @include fontStyling($robotoFont, 400);
  margin: 1rem 0;
  transition: .3s all;

  &:hover {
    box-shadow: 5px 5px 10px rgba(150, 150, 150, .3);
  }

  &__link {
    text-decoration: none;
  }

  &__section {
    padding: 1rem;

    &_top {
      height: 12rem;
    }

    &_bottom {
      max-height: 6rem;
    }
  }

  &__title {
    @include fontStyling($robotoFont, 700);
    @include setFontColor(navyFont);
    font-size: 1.5rem;
  }

  &__image-container {
    max-height: 10.5rem;
    width: 100%;
    overflow: hidden;
    position: relative
  }

  &__img {
    width: 100%;
    object-position: center;
  }

  &__badge {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__text {
    @include setFontColor(listFont);
    padding: .5rem 0;
    font-size: .75rem;
    &_sm {
      line-height: 1.25rem;
      display: block;
    }
  }

  &__date-n-place, &__place, &__date {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__date-n-place {
    margin-bottom: .75rem;
    justify-content: space-between;
  }

  &__place, &__date {
    margin-right: 1rem;
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: rgba(200, 200, 200, .8)
  }

  &__row-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__button {
    @include fontStyling($robotoFont, 400);
    font-size: .75rem;
  }

  &__info {
    @include setFontColor(listFont);
    @include fontStyling($robotoFont, 400);
    font-size: .875rem;
    padding: 0 .375rem
  }
}

.congress-card-badge {
  background-color: rgba(255, 255, 255, .9);
  padding: .5rem;
  @include fontStyling($robotoFont, 300);
  font-size: .75rem;
  @include setFontColor(navyFont);
  text-align: center;

  &_bold {
    font-weight: 700;
  }

  &__number {
    font-size: 1.5rem;
    font-weight: 600;
    display: block;
    margin-top: .25rem;

    &_sm {
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

@include media-breakpoint-down(sm) {
  .congress-card {
    &__section {
      &_top {
        height: unset;
      }
    }

    &__info {
      font-size: .75rem;
    }
  }
}