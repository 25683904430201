.edu-points-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255, 255, 255, .65);
  border-radius: 50%;
  margin-right: 1rem;
  text-align: center;
  width: 5.25rem;
  height: 5.25rem;
  justify-content: center;

  &__number {
    @include fontStyling($openFont, 700);
    color: map_get($fontColors, darkGreyFont);
    display: inline-block;
    font-size: 1.375rem;
    line-height: 1.375rem;
    text-align: center;
  }

  &__text {
    @include fontStyling($openFont, 400);
    text-align: center;
    color: map_get($fontColors, darkGreyFont);
    font-size: 10px;
    margin-top: .25rem;
  }
}

@include media-breakpoint-down(md) {
  .edu-points-badge {
    display: none;
  }
}