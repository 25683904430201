.contact {
  &__question {
    display: flex;
    @include fontStyling($nunitoFont, 300);
    align-items: center;
    margin-bottom: .5rem;
    font-size: 1.125rem;
  }

  &__icon {
    fill: map_get($pageColors, contactIcon);
  }
}

.contact-question {
  padding-right: 1rem;
}

.contact-form {
  width: 100%;

  &__text {
    @include fontStyling($nunitoFont, 300);
    margin-bottom: .5rem;

    &_sm {
      font-size: .75rem;
      line-height: 1rem
    }

    &_md {
      font-size: .875rem;
      line-height: 1.125rem
    }

    &_lg {
      font-size: 1rem;
      margin: .75rem 0;
      line-height: 1.25rem
    }
  }
}

.form {
  &__input {
    &:not([type="submit"]) {
      width: 100%;
    }
  }

  &__label {
    display: none;
  }

  &__textarea, &__input {
    @include fontStyling($nunitoFont, 300);
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &__error {
    display: inline-block;
    @include fontStyling($nunitoFont, 300);
    @include setFontColor(lightGreyFont);
    margin: 0 1rem;
    font-size: .75rem;
    pointer-events: none;
  }

  &__success-message {
    transition: .3s all;
    @include setFontColor(greenFont);
    font-size: 0.825rem;
    @include fontStyling($nunitoFont, 400);
    margin-left: .375rem;
  }
}

.newsletter-form-container {
  width: 100%;
  background-size: cover;
  background-color: rgba(170, 170, 170, .3);
  box-shadow: 2px 2px 5px 2px rgba(201, 204, 224, .5);
  position: relative;
  z-index: 0;
  padding: 0 1rem .25rem;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .75);
    z-index: -1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.newsletter-form {
  &__label {
    display: flex;
    align-items: baseline;
    margin-bottom: .5rem;
  }

  &__text {
    @include fontStyling($nunitoFont, 300);
    @include setThemeColor(color, themeColorBasic)
  }
}

.contact-data {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  &__link, &__title {
    @include fontStyling($nunitoFont, 300);
    @include setThemeColor(color, themeColorBasic);
    display: inline-block;
    text-decoration: none;
    padding: .25rem 0;

    &:hover, &:active, &:focus {
      @include setThemeColor(color, themeColorBasic);
    }
  }

  &__title {
    font-weight: 400;
  }

  &__image {
    margin-right: 1rem;
  }
}

@include media-breakpoint-down(sm) {
  .newsletter-form-container {
    width: unset;
  }
}