.previous-congresses {
  &__annotation {
    @include fontStyling($nunitoFont, 300);
    font-size: 1rem;
    @include setFontColor(lightGreyFont);
    text-align: right;
    margin-top: .5rem;
  }
}

.gallery-attached {
  width: 100%;

  &__row {
    width: 100%;
    display: flex;
  }

  &__col {
    display: flex;
    flex-direction: column;
    max-height: 15rem;

    @for $i from 12 through 1 {
      &_#{$i} {
        width: (100%/12)*$i;
      }
    }
  }

  &__image-container {
    position: relative;
    cursor: pointer;
    height: 100%;
    max-width: 100%;
  }

  &__image, &__video {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;

    &_sm {
      height: 50%;
    }
  }

  &__video {
    max-width: 100%;
  }
}

.gallery-text-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @include setBackgroundColor(textColBackground);
  align-items: center;
  justify-content: center;
  @include fontStyling($nunitoFont, 300);
  text-align: center;
  @include setThemeColor(color, themeColorDark);
  padding: 1rem;

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 1rem;
    margin: .375rem 0;
  }

  &__number {
    font-size: 2.5rem;
    margin: .75rem 0 0 0;
  }

  &__description {
    font-size: 1rem;
    margin: .25rem 0 .75rem 0;
    @include setThemeColor(color, themeColorLight);
  }
}

@include media-breakpoint-down(sm){
  .gallery-attached {
    &__row {
      flex-direction: column;
      max-height: unset;
    }

    &__col {
      flex-direction: row;
      max-width: 100%;
      @for $i from 12 through 1 {
        &_#{$i} {
          width: 100%;
        }
      }
    }

    &__image-container {
      width: 100%;
    }

    &__image, &__video {
      width: 100%;
      object-fit: cover;
      object-position: center;

      &_sm {
        width: 50%;
      }
    }
  }

  .gallery-text-block {
    padding: 1rem 0;

    &__row {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    &__info {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}